import React from "react";
import PropTypes from "prop-types";
import "./SocialLink.scss";
import gitIcon from "../../images/github.svg";
import linkedinIcon from "../../images/linkedin.svg";
import mailIcon from "../../images/mail.svg";
import resumeIcon from "../../images/resume.svg";

const types = {
  github: {
    url: "https://github.com/paulmedeiros92",
    imgSrc: gitIcon,
    download: false,
  },
  linkedin: {
    url: "https://www.linkedin.com/in/paulmed/",
    imgSrc: linkedinIcon,
    download: false,
  },
  mail: {
    url: "mailto:paulmedeiros92@gmail.com?subject=I saw your cool site!",
    imgSrc: mailIcon,
    download: false,
  },
  resume: {
    url: "/Paul_Medeiros_Resume.pdf",
    imgSrc: resumeIcon,
    download: true,
  },
};

const SocialLink = ({ type }) => (
  <div className="SocialLink">
    <a href={types[type].url} download={types[type].download}>
      <img src={types[type].imgSrc} alt={type} />
    </a>
  </div>
);

SocialLink.propTypes = {
  type: PropTypes.oneOf(Object.keys(types)).isRequired,
};

SocialLink.defaultProps = {};

export default SocialLink;
